import * as React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { Card as MuiCard } from '@mui/material';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';

import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import SendIcon from '@mui/icons-material/Send';

import getSignUpTheme from './getSignUpTheme';
import ToggleColorMode from './ToggleColorMode';
import './UserChat.css';
import ChatHistory from '../chat-history/ChatHistory';


function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        aria-label="Toggle design language"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};


export default function UserChat() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignUpTheme = createTheme(getSignUpTheme(mode));
  const [question, setQuestion] = React.useState('');
  const [chatHistory, setChatHistory] = React.useState([]);
  const newestAnswerRef = React.useRef(null);
  const [isQuestionSet, setIsQuestionSet] = React.useState(false);
  const [pastedImage, setPastedImage] = React.useState(null);

  const suggestedQuestions = [
    "How much experience do you have with Azure CI/CD?",
    "Can you describe a challenging project you led at Axis Capital?",
    "What was your role in the corporate website overhaul at Axis Capital?",
    "How did you manage team development and mentorship at Axis Capital?",
    "What innovative solutions did you implement at Updater, Inc.?",
    "Why do you aspire to become a Staff Software Engineer or Principal Software Engineer?",
    "What makes you a strong candidate for a Staff Software Engineer or Principal Software Engineer role?",
    "Can you provide examples of your leadership and technical skills in action?",
    "How do you approach problem-solving and decision-making in complex projects?",
    "What role does mentorship play in your career, and how do you approach it?",
    "How do you ensure that your technical solutions align with business objectives?",
    "What did you learn from the “Being A Leader” program at Axis Capital?",
    "How has the 'Being A Leader' program influenced your leadership style?",
    "Can you provide an example of how you applied the principles from 'The Multiplier' in your work?",
    "How do you identify and leverage the strengths of your team members?",
    "What challenges did you face while implementing the principles from the 'Being A Leader' program, and how did you overcome them?",
    "How extensive is your experience with Azure Cloud?",
    "What experience do you have with third-party integrations?",
    "How do you use JIRA in your projects?",
    "What is your experience with CircleCI?",
    "Can you describe your role in leading deployments and preparing rollback scripts?",
    "How do you handle interactions with security teams and change management processes?",
    "What is your experience with ServiceNow?",
    "How important is documentation in your work, and how do you approach it?",
    "How do you ensure the security of your cloud-based solutions?",
    "Can you give an example of a challenging third-party integration you handled?",
    "How do you manage the deployment process to ensure minimal downtime and quick recovery from issues?"
  ];  

  const askQuestion = async () => {
    debugger;
    try {
      let sessionId = localStorage.getItem('sessionId');
      // let cleanedQuestion = question.replace(/[^a-zA-Z0-9 ,.'"!?-\[\]:\/]/g, ''); // Allow base64 characters
      let params = { question: question };
      if (sessionId) {
        params.sessionId = sessionId;
      }
      // random comment
      const response = await axios.post('/ask', params);
      console.log('Response:', response?.data?.answer);
      console.log('Response:', typeof (response?.data?.answer));
      sessionId = response?.data?.sessionId ? response?.data?.sessionId : sessionId;
      localStorage.setItem('sessionId', sessionId);

      setChatHistory(response?.data?.chatHistory);
      setQuestion('');
      setIsQuestionSet(false);
    } catch (error) {
      console.error('Error asking question:', error);
      setIsQuestionSet(false);
    }
  };


  React.useEffect(() => {
    // Scroll to the newest answer if the chat history changes
    if (newestAnswerRef.current) {
      newestAnswerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      askQuestion();
    }
  };

  const setAndAskQuestion = (event, question) => {
    event.preventDefault();
    setQuestion(question);
    setIsQuestionSet(true);
  };

    // useEffect to call askQuestion when the question state is updated
    React.useEffect(() => {
      debugger;
      if (isQuestionSet && question !== '') {
        askQuestion();
      }
    }, [isQuestionSet, question]); // Depend on both isQuestionSet and question

  return (
    <>
      <div style={{ overflow: 'auto' }}>

        <div className="container">
          <ThemeProvider theme={showCustomTheme ? SignUpTheme : defaultTheme}>
            <CssBaseline />
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            <div className="row">
              <div className="col-lg-12">
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                >
                  Resume Chat
                </Typography>
              </div>
              <div className="col-lg-12">
            <ChatHistory newestAnswerRef={newestAnswerRef} chatHistory={chatHistory}/>
          </div>
              <div className="col-lg-12">
                <Carousel data-bs-theme="dark">
              {suggestedQuestions && suggestedQuestions.length > 0 &&
                suggestedQuestions.map((question, index) =>
                  <Carousel.Item key={`suggestedQuestion-${index}`}>
                    <h5 className='mt-5 questionSuggestions' style={{ height: '75px' }}><a onClick={(e) => setAndAskQuestion(e, question) } href="#" role="button">{question}</a></h5>
                    <Carousel.Caption>
                    </Carousel.Caption>
                  </Carousel.Item>
                )
              }
                </Carousel>
              </div>

              <div className='row'>
                <div className='col-lg-12'>
                  <Form.Label htmlFor="inputPassword5"></Form.Label>
                  <Form.Control
                    onChange={e => setQuestion(e.target.value)}
                    onKeyDown={handleKeyDown}
                    value={question}
                    as="textarea" rows={2}
                    id="chat-question-control"
                    placeholder="Resume Chat"
                  />

                  <Form.Text id="chat-question" muted>
                    Ask about my work experience or experience with a certain framework.
                  </Form.Text>
                  <div style={{ position: 'relative' }}>
                    <Button
                      id="ask-button"
                      type="Ask"
                      onClick={askQuestion}
                      style={{ position: 'absolute', bottom: 25, right: 10 }}
                    >
                      <SendIcon />
                    </Button>
                  </div>
                </div>
              </div>


            </div>
          </ThemeProvider>
        </div>


      </div>
    </>
  );
}
