import logo from './logo.svg';
import React, { useState } from 'react';
import UserChat from './components/user-chat/UserChat';
import './App.css';
import CodeChat from './components/user-chat/CodeChat/CodeChat';
import Konami from 'react-konami-code';

function App() {
  const [konamiEnabled, setKonamiEnabled] = useState(false);
  const easterEgg = () => {
    debugger;
    setKonamiEnabled(!konamiEnabled);
  }
  return (
    <div className="App">
      {!konamiEnabled &&
        <UserChat />
      }
      <Konami code={[50,50]} action={easterEgg}>
        <CodeChat />
      </Konami>
    </div>
  );
}

export default App;


