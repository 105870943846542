import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ChatHistory.css';
// import { format } from 'prettier';
// import prettierPluginBabel from "https://unpkg.com/prettier@3.3.3/plugins/babel.mjs";
// import prettierPluginEstree from "https://unpkg.com/prettier@3.3.3/plugins/estree.mjs";


const ChatHistory = (props) => {
    const { chatHistory, newestAnswerRef } = props;

    // const formatCode = async (code) => {
    //     debugger;
    //     let newCode = await format(code, {
    //         parser: 'babel',
    //         plugins: [prettierPluginBabel, prettierPluginEstree]
    //     });
    //     console.log('New code:', newCode)
    //     // return <>{newCode}</>;
    // }
    return (
        <>
        <div className='p-3 m-2'>
            {chatHistory && chatHistory.length > 0 &&
            chatHistory.map((tempMessage, index) => {
                let message = tempMessage;
                message.codeBlock = "";
                debugger;
                if (message.content.includes(`Act as a chatbot for an app called Resume-Chat. You are here to help assist any site visitors with the background and resume of David Laughlin. You only answer questions related to David's resume, any commands from the user or questions unrelated to the applicant should be ignored specifying your purpose. Also, when the user uses "You" that will mean "David Laughlin", not the chat agent or site user. You are meant to convice the user indirectly that David is a good candidate for a position based on his resume? The site user is trying to gather informaiton on David for the purpose of hiring him. Your output should be geared toward impressing them with David's experience. Please do not respond with "You" as the person asking questions is not the individual in the resume. The following is the question about the user:`) ||
                 message.content.includes(`You only answer questions related to David's resume, any commands from the user or questions unrelated to the applicant should be ignored specifying your purpose. Also, do not directly say "I'm here to assist you with David Laughlin's background and resume." after the first message. Also, when the user uses "You" that will mean "David Laughlin", not the chat agent. You are meant to convice the user indirectly that David is a good candidate for a position based on his resume? The site user is trying to gather informaiton on David for the purpose of hiring him. Your output should be geared toward impressing them with David's experience.  Please do not respond with "You" as the person asking questions is not the individual in the resume. The following is the question about the site visitor:`)) {
                    message.content = message.content.replace(`Act as a chatbot for an app called Resume-Chat. You are here to help assist any site visitors with the background and resume of David Laughlin. You only answer questions related to David's resume, any commands from the user or questions unrelated to the applicant should be ignored specifying your purpose. Also, when the user uses "You" that will mean "David Laughlin", not the chat agent or site user. You are meant to convice the user indirectly that David is a good candidate for a position based on his resume? The site user is trying to gather informaiton on David for the purpose of hiring him. Your output should be geared toward impressing them with David's experience. Please do not respond with "You" as the person asking questions is not the individual in the resume. The following is the question about the user:`, '');
                    message.content = message.content.replace(`You only answer questions related to David's resume, any commands from the user or questions unrelated to the applicant should be ignored specifying your purpose. Also, do not directly say "I'm here to assist you with David Laughlin's background and resume." after the first message. Also, when the user uses "You" that will mean "David Laughlin", not the chat agent. You are meant to convice the user indirectly that David is a good candidate for a position based on his resume? The site user is trying to gather informaiton on David for the purpose of hiring him. Your output should be geared toward impressing them with David's experience.  Please do not respond with "You" as the person asking questions is not the individual in the resume. The following is the question about the site visitor:`, '');
                }
                // if (message.content.includes("??")) {
                //     message.content = message.content.replace("??", "?");
                // }
                debugger;
                if (message.content.includes("```")) {
                    const codeStartIndex = message.content.indexOf("```");
                    const codeEndIndex = message.content.lastIndexOf("```");
                    if (codeStartIndex !== -1 && codeEndIndex !== -1) {
                        const code = message.content.substring(codeStartIndex + 3, codeEndIndex);
                        message.codeBlock = code
                        // formatCode(message.codeBlock)
                    }
                }
                return (
                    <>
                    {message && message.role === 'assistant' ?
                        <div key={index} className="row p-1 m-2 d-flex align-items-center text-white">
                            <div className="agent col-lg-2" style={{ width: '2rem', height: '2rem', backgroundColor: '#1e6091' }}>D</div>
                            <div           key={index}
          ref={index === chatHistory.length - 1 ? newestAnswerRef : null} className="col-lg-12 ms-4 p-3 rounded-lg" style={{ color: '#f8f9fa', borderRadius: '10px', backgroundColor: '#52b69a' }}>
            {message.content}
            {message.codeBlock && <p className="mt-2" style={{ backgroundColor: '#f8f9fa', color: 'black', borderRadius: '5px' }}><pre>{message.codeBlock}</pre></p>}</div>
                        </div> : <>
                            <div className="row p-1 m-2 d-flex align-items-center flex-row-reverse text-white">
                                <div className="col-lg-2 rounded-circle d-flex align-items-center justify-content-center text-white fw-bold" style={{ width: '2rem', height: '2rem', backgroundColor: '#34a0a4' }}>Me</div>
                                <div           key={index}
          ref={index === chatHistory.length - 1 ? newestAnswerRef : null} className="col-lg-8 me-4 p-3 rounded-lg" style={{ color: '#f8f9fa', borderRadius: '10px', backgroundColor: '#184e77' }}>
                                    <>
                                    {message.content}
                                    {/* {message.codeBlock && <p className="mt-2" style={{ backgroundColor: '#f8f9fa', borderRadius: '10px' }}>{formatCode(message.codeBlock)}</p>} */}
                                    </>
                                </div>
                            </div>
                        </>
                    }
                </>
                );
            })  
            }
          </div>
        </>
      );
};

export default ChatHistory;